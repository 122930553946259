import React, {useEffect} from 'react';
import {useRouter} from 'next/dist/client/router';
import UiHeader from '../src/components/common/UiHeader';
import UiFooter from '../src/components/common/UiFooter';
import {CompanyService} from '../src/services/company.service';
import {GetStaticProps} from 'next';
import Head from "next/head";
import {initTranslationBrowser} from "../src/translation/translation-browser";
import {getTranslationProps} from "../src/translation/translation-ssr";
import {GTMTickerNotFound} from "../src/analytics/google-tag-manager";

const CompanyNotFound = (props: { tickers: string[] }) => {
  initTranslationBrowser()
  const router = useRouter();
  const isStock = /(\/stocks\/|\/reszveny\/).*/g.test(router.asPath);

  useEffect(() => {
    if (isStock) {
      const tickerRegExp = /^(\/stocks\/|\/reszveny\/)(.*)/;
      const result = router.asPath.match(tickerRegExp);
      const ticker = result && result[2];
      ticker && GTMTickerNotFound(ticker)
    }
  }, []);

  return (
    <div className="not-found">
       <Head>
        <title>Ticker not found</title>
        <meta charSet="utf-8" />
        <meta name="Description" content="Ticker not found" />
      </Head>
      <UiHeader isHomepage={false} />
      <div className="company-not-found-container">
        <div className="section-company-not-found">
          <div className="company-not-found-header">{isStock ? 'Ticker not found' : '404'}</div>
          {isStock ? (
            <div className="company-not-found-text">
              The ticker that you entered could not be found in our database. Possible reasons: <br />
              <ul>
                <li>
                  The company is not yet available. Currently, only a part of the companies on US exchanges are tracked by iO Charts. We are constantly working on making as many stocks available as
                  possible.
                </li>
                <li>You entered a non-existent ticker</li>
                <li>We might be experiencing technical difficulties on our backend</li>
              </ul>
              Please use the search bar at the top to browse the list of available stocks.
            </div>
          ) : (
            <div className="company-not-found-text">Page not found</div>
          )}
        </div>
      </div>
      <UiFooter />
    </div>
  );
};

export const getStaticProps: GetStaticProps = async ({ params,locale }) => {
  // Get the paths we want to pre-render based on users
  process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';
  try {
    const tickers = await CompanyService.getTickers();
    return { props: { tickers: tickers, ...(await getTranslationProps(locale)) } };
  } catch (err) {
    console.error(err);
    return { props: { tickers: [], ...(await getTranslationProps(locale)) } };
  }
};

export default CompanyNotFound;
